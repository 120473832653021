

































































































































































































import config from "@/config";
import {VtableHeader} from "@/store/modules/metaBlock";
import {Component, Vue, Watch} from "vue-property-decorator";
import {Getter, Action, State} from "vuex-class";
import Paginate from "vuejs-paginate";
import MyBackTag from "@/components/common/backTag.vue";
import * as echarts from "echarts";
import {UserInfoTypes} from "@/metaid-types";
import {mcDetailPath} from "@/store/modules/mcDetail";
import MyCountTo from "@/components/common/myCountTo.vue";
import formatNum from "format-number";
import {ECharts, number} from "echarts";

@Component({
  name: "mcBlocks",
  filters: {
    protocolSplit(val: string, tag = "-") {
      return val.split(tag)[0];
    }
  },
  components: {
    Paginate,
    MyBackTag,
    MyCountTo
  }
})
// eslint-disable-next-line @typescript-eslint/class-name-casing
export default class mcBlocks extends Vue {
  @Getter("mcDetail/tableHeader") tableHeader!: Array<VtableHeader>;
  @Getter("mcDetail/tableData") tableData!: Array<any>;
  @Getter("mcDetail/tableAllData") tableAllData!: Array<any>;
  @Getter("mcDetail/hasmetablockData") hasmetablockData!: number;
  @Getter("mcHistoryTableMaxPages") mcHistoryTableMaxPages!: number;
  @Getter("currentUser") currentUser!: UserInfoTypes;
  @Action("mcDetail/commitTableHeader") commitTableHeader!: Function
  page = 1;
  pageSize: number = config.tablePage;
  title = "";
  mcBlockSize: number = config.mcBlockSize as number;
  mcChart?: ECharts;
  mcChart2?: ECharts;
  startIdx = 0;
  endIdx: number = config.tablePage - 1;
  chartData: Array<any> = [];
  readonly items = [{
    name: '体积',
    type: 0
  }, {
    name: '上链费',
    type: 1
  }];
  tabSelect =0;

  @Watch('tableHeader')
  changetableHeader(newV: any) {
    console.log(newV)
  }

  @Watch('tabSelect')
  selectChange(newV: number) {
    this.commitTableHeader({type: newV})
    this.$nextTick(() => {
      if (this.tabSelect === 0) {
        this.initCharts2();
      } else {
        this.initCharts();
      }
    })
  }

  async getTableData() {
    // this.$store.dispatch(MetaBlockTablePath.getBlockTxList, {
    //   Page: this.page.toString(),
    //   PageSize: this.pageSize.toString(),
    // });
    this.$store.dispatch(mcDetailPath.getMetaBlocsAction);
  }

  inputChange() {
    // this.getTableData();
    this.$store.commit(mcDetailPath.updateTableData, {
      page: this.page,
      pageSize: config.tablePage
    });
    this.getTableItemIdx(this.chartData);

    if (this.tabSelect === 1) {
      this.mcChart &&
      this.mcChart.dispatchAction({
        type: "dataZoom",
        startValue: this.startIdx,
        endValue: this.endIdx
      });
    } else {
      this.mcChart2 &&
      this.mcChart2.dispatchAction({
        type: "dataZoom",
        startValue: this.startIdx,
        endValue: this.endIdx
      });
    }
  }

  getTableItemIdx(tableAllData: Array<any> = []) {
    //不处理图表操作
    const startItem = this.tableData[0];
    const endItem = this.tableData[this.tableData.length - 1];
    for (let i = 0; i < tableAllData.length; i++) {
      const tableDataItem = tableAllData[i];
      if (tableDataItem.block == startItem.block) {
        this.startIdx = i;
      }
      if (tableDataItem.block == endItem.block) {
        this.endIdx = i;
      }
    }
  }

  mounted() {
    this.title = `$vuetify.${this.$route.meta.title}`;
    this.getTableData();
    this.initData();
  }

  initData() {
    if (this.tableAllData.length == 0) {
      this.$store.watch(
          (state: any) => {
            return state.mcDetail.tableAllData;
          },
          (allData: Array<any>) => {
            console.log("更新了多少次？");
            const list = [...allData];
            this.chartData = [...list.reverse()];
            this.getTableItemIdx(this.chartData);
            if (this.tabSelect === 0) {
              this.initCharts2();
            } else {
              this.initCharts();
            }
          }
      );
    } else {
      this.$store.watch(
          (state: any) => {
            return state.mcDetail.tableAllData;
          },
          (allData: Array<any>) => {
            console.log("更新了多少次？");
            const list = [...allData];
            this.chartData = [...list.reverse()];
            this.getTableItemIdx(this.chartData);
            if (this.tabSelect === 0) {
              this.initCharts2();
            } else {
              this.initCharts();
            }
          }
      );
    }
  }

  initCharts() {
    const mcChartEl: any = this.$refs.mcChart;
    this.mcChart = echarts.init(mcChartEl);
    const mcBlockSize = this.mcBlockSize;
    const tableAllData = this.chartData;

    this.mcChart &&
    this.mcChart.setOption({
      xAxis: {
        type: "category",
        data: tableAllData.map(function (item) {
          if (item.block < 10) {
            return "0" + item.block;
          } else {
            return item.block.toString();
          }
        }),
        max: "dataMax",
        splitNumber: 6000
      },
      grid: {
        left: "60px",
        right: "0"
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params: any) {
          if (params[0].value) {
            let val: any = Math.round(params[0].value * mcBlockSize);
            val = formatNum()(val);
            val = "总上链费:" + val + " sats";
            const blockHeigh: any = "区块高度:" + params[0].axisValue;
            const divWrap: HTMLElement = document.createElement("div");
            const blockHeightDiv: HTMLElement = document.createElement("div");
            blockHeightDiv.innerText = blockHeigh;
            const valDiv: HTMLElement = document.createElement("div");
            valDiv.innerText = val;
            divWrap.appendChild(blockHeightDiv).appendChild(valDiv);
            return divWrap;
          } else {
            const blockHeigh: any = "区块高度:" + params[0].axisValue;
            const divWrap: HTMLElement = document.createElement("div");
            const blockHeightDiv: HTMLElement = document.createElement("div");
            blockHeightDiv.innerText = blockHeigh;
            const valDiv: HTMLElement = document.createElement("div");
            valDiv.innerText = "总上链费:--sats";
            divWrap.appendChild(blockHeightDiv).appendChild(valDiv);
            return divWrap;
          }
        }
      },
      dataZoom: [
        {
          type: "inside",
          startValue: this.startIdx,
          endValue: this.endIdx
        },
      ],
      yAxis: {
        nameLocation: "start",
        type: "value",
        axisLabel: {
          formatter: "{value} Bsv"
        }
      },
      series: [
        {
          data: tableAllData.map(item => {
            return item.value / this.mcBlockSize;
          }),
          type: "line",
          smooth: 0.2,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(67, 93, 253, 0.2)"
              },
              {
                offset: 0.5,
                color: "rgba(67, 93, 253, 0.1)"
              },
              {
                offset: 1,
                color: "rgba(67, 93, 253, 0)"
              },
            ])
          }
        },
      ]
    });
  }

  initCharts2() {

    const mcChartEl: any = this.$refs.mcChart2;
    this.mcChart2 = echarts.init(mcChartEl);
    const mcBlockSize = this.mcBlockSize;
    const tableAllData = this.chartData;

    this.mcChart2 &&
    this.mcChart2.setOption({
      xAxis: {
        type: "category",
        data: tableAllData.map(function (item) {
          if (item.block < 10) {
            return "0" + item.block;
          } else {
            return item.block.toString();
          }
        }),
        max: "dataMax",
        splitNumber: 6000
      },
      grid: {
        left: "60px",
        right: "0"
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params: any) {
          if (params[0].value) {
            let val: any = Math.round(params[0].value);
            // val = formatNum()(val);
            val = "(" + val + " bytes)";
            let defaultVal: any = (
                Math.round(params[0].value) / 1073741824
            ).toFixed(1);
            defaultVal = "大小: " + defaultVal + " GB";
            const blockHeigh: any = "区块高度:" + params[0].axisValue;
            const divWrap: HTMLElement = document.createElement("div");
            const blockHeightDiv: HTMLElement = document.createElement("div");
            blockHeightDiv.innerText = blockHeigh;
            const valDivM: HTMLElement = document.createElement("div");
            valDivM.innerText = defaultVal
            const valDiv: HTMLElement = document.createElement("div");
            valDiv.innerText = val;
            divWrap
                .appendChild(blockHeightDiv)
                .appendChild(valDivM)
                .appendChild(valDiv);
            return divWrap;
          } else {
            const blockHeigh: any = "区块高度:" + params[0].axisValue;
            const divWrap: HTMLElement = document.createElement("div");
            const blockHeightDiv: HTMLElement = document.createElement("div");
            blockHeightDiv.innerText = blockHeigh;
            const valDiv: HTMLElement = document.createElement("div");
            valDiv.innerText = "大小:0bytes";
            divWrap.appendChild(blockHeightDiv).appendChild(valDiv);
            return divWrap;
          }
        }
      },
      dataZoom: [
        {
          type: "inside",
          startValue: this.startIdx,
          endValue: this.endIdx
        },
      ],
      yAxis: {
        nameLocation: "end",
        type: "value",
        axisLabel: {
          formatter(val: any) {
            if (val === 0) {
              return 0
            }
            if (val >= 1000 && val < 1000000) {
              val = (val / 1000).toFixed(1) + "KB"
              return val
            } else if (val >= 1000000 && val < 1000000000) {
              val = (val / 1000000).toFixed(1) + "MB"
              return val
            } else if (val >= 1000000000 && val < 1000000000000) {
              val = (val / 1000000000).toFixed(1) + "GB"
              return val
            } else {
              val = val + " byte"
              return val
            }
          }
        }
      },
      series: [
        {
          data: tableAllData.map(item => {
            return item.size;
          }),
          type: "line",
          smooth: 0.2,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(67, 93, 253, 0.2)"
              },
              {
                offset: 0.5,
                color: "rgba(67, 93, 253, 0.1)"
              },
              {
                offset: 1,
                color: "rgba(67, 93, 253, 0)"
              },
            ])
          }
        },
      ]
    });
  }
}
